import { useCallback, useState } from 'react';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';

import { useIsFullscreen } from '@/hooks/useIsFullscreen';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { useExitFullscreen } from '@/modules/media-player/hooks/useFullscreen';
import { PlayerScrubber } from '@/modules/media-player/PlayerScrubber';

import { StoryPlayerPlayButton } from '../StoryPlayer/StoryPlayerPlayButton';
import { StoryVideoPlayer } from '../StoryVideoPlayer/StoryVideoPlayer';

import {
  Container,
  Content,
  FullscreenCloseButton,
  MediaWrapper,
  PlayerScrubberWrapper,
} from './HighlightReelPlayer.styles';

interface HighlightReelPlayerProps {
  controlsVisible: boolean;
  promptText: string;
  onVideoClick: () => void;
}

export function HighlightReelPlayer({ controlsVisible, promptText, onVideoClick }: HighlightReelPlayerProps) {
  const isFullscreen = useIsFullscreen();
  const mobile = useIsMobileViewport();
  const handleExitFullscreen = useExitFullscreen();

  const [hovering, setHovering] = useState(false);

  const handleHover = useCallback(
    (hovering: boolean) => {
      if (mobile) return;

      setHovering(hovering);
    },
    [mobile],
  );

  const showing = mobile ? controlsVisible : hovering;

  return (
    <Container>
      <Content>
        {isFullscreen && showing && (
          <FullscreenCloseButton
            icon={faXmark}
            tooltip={{ label: 'Close', position: 'bottom' }}
            color="white"
            backgroundColor="transparent"
            onClick={handleExitFullscreen}
            onMouseOver={() => handleHover(true)}
            onMouseOut={() => handleHover(false)}
          />
        )}

        <MediaWrapper
          onClick={mobile ? onVideoClick : undefined}
          onMouseOver={() => handleHover(true)}
          onMouseOut={() => handleHover(false)}
        >
          {mobile && controlsVisible && <StoryPlayerPlayButton />}
          <StoryVideoPlayer fitMode={isFullscreen ? 'contain' : 'cover'} />

          <PlayerScrubberWrapper
            onClick={(event) => event.stopPropagation()}
            showing={showing}
            onMouseOver={!mobile ? undefined : () => handleHover(true)}
            onMouseOut={!mobile ? undefined : () => handleHover(false)}
          >
            <PlayerScrubber promptText={promptText} />
          </PlayerScrubberWrapper>
        </MediaWrapper>
      </Content>
    </Container>
  );
}
