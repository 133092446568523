import { useState } from 'react';

import { usePromptFirstQuestionQuery } from '@/services/api/project';
import { useStoryQuery } from '@/services/api/story';

import { HighlightReelPlayer } from '../components/HighlightReelPlayer/HighlightReelPlayer';

export interface HighlightReelPlayerContainerProps {
  storyId: string;
}

export function HighlightReelPlayerContainer({ storyId }: HighlightReelPlayerContainerProps) {
  const storyQuery = useStoryQuery(storyId);
  const promptQuestionQuery = usePromptFirstQuestionQuery(storyQuery.data?.promptId ?? null);

  const [controlsVisible, setControlsVisible] = useState(true);

  return (
    <HighlightReelPlayer
      controlsVisible={controlsVisible}
      onVideoClick={() => setControlsVisible((controlsVisible) => !controlsVisible)}
      promptText={promptQuestionQuery.data?.text ?? ''}
    />
  );
}
